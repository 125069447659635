"use client"
import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image'
import Link from 'next/link';
import './styles.scss';

export default function ImageButton({
  imageHover = '',
  children = null,
  onClick = () => {},
  link = '',
  image,
  height,
  width,
  // imageHover,
  wrapperClass,
  imageAlt,
}){

  const handleClick = () => {
    if(onClick){
      onClick();
    }
  }


  if(link) {
    return (
      <Link href={link}>
        <button className={`imageButton ${wrapperClass}`} onClick={handleClick} style={{height, width}} >
          {image && (
            <Image fill src={image} alt={imageAlt} className="buttonImage" />
          )}
          {/* imageHover && <img src={imageHover} alt="" className="buttonImageHover" /> */}
          {children && <span className="imageButtonText">{children}</span>}
        </button>
      </Link>
    )
  } 
  return(
    <button className={`imageButton ${wrapperClass}`} onClick={handleClick} style={{height, width}}>
      {image && (
            <Image fill src={image} alt={imageAlt} className="buttonImage" />
          )}
      {/* imageHover && <img src={imageHover} alt="" className="buttonImageHover" /> */}
      {children && <span className="imageButtonText" >{children}</span>}
    </button>
  );
  
} 

ImageButton.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  imageHover: PropTypes.string,
  onClick: PropTypes.func,
  link: PropTypes.string,
  children: PropTypes.node,
  wrapperClass: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

