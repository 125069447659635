import { petTypes } from '../pets'
export const FRAME_MAX_DURATION = 2;
export const FRAME_MIN_DURATION = 0.25;

export const giveMeRandomDurationInMilliSecondsForFrame = (min = FRAME_MIN_DURATION, max = FRAME_MAX_DURATION) =>{
  const newDuration = (Math.random() * (max - min) + min) * 1000;
  return newDuration;
}

export const setUpPetFromLibrary = (pets, type) => {
  const frames = [];
  switch (type) {
    case petTypes.EATMENU:{
      const frameA = {src: pets.eatMenuPet1, minDuration: 0.25, maxDuration: 3}
      const frameB = {src: pets.eatMenuPet2, minDuration: 0.15, maxDuration: 0.35}
      frames.push(frameA);
      frames.push(frameB);
    }
      break;
    case petTypes.DRINKMENU:{
      const frameA = {src: pets.drinkMenuPet1, minDuration: 0.25, maxDuration: 3}
      const frameB = {src: pets.drinkMenuPet2, minDuration: 0.15, maxDuration: 0.35}
      frames.push(frameA);
      frames.push(frameB);
    }
      break;
    case petTypes.HELP:{
      const frameA = {src: pets.helpMobilePet2, minDuration: 0.75, maxDuration: 3}
      const frameB = {src: pets.helpMobilePet1, minDuration: 0.1, maxDuration: 0.2}
      frames.push(frameA);
      frames.push(frameB);
    }
      break;
    case petTypes.NORMAL:{
      const frameA = {src: pets.defaultMobilePet2, minDuration: 0.85, maxDuration: 3}
      const frameB = {src: pets.defaultMobilePet1, minDuration: 0.1, maxDuration: 0.2}
      frames.push(frameA);
      frames.push(frameB);
    }
      break;
    case petTypes.SLEEPING: {
      const frameA = {src: pets.defaultMobilePet2, minDuration: 0.25, maxDuration: 3}
      frames.push(frameA);
    }
      break;
    case petTypes.SAD:{
      const frameA = {src: pets.errorMobilePet1, minDuration: 0.25, maxDuration: 3}
      const frameB = {src: pets.errorMobilePet2, minDuration: 0.15, maxDuration: 0.35}
      frames.push(frameA);
      frames.push(frameB);
    }
      break;
    case petTypes.OK:{
      const frameA = {src: pets.okMobilePet1, minDuration: 0.25, maxDuration: 3}
      const frameB = {src: pets.okMobilePet2, minDuration: 0.15, maxDuration: 0.35}
      frames.push(frameA);
      frames.push(frameB);
    }
      break;
    case petTypes.THANKS:{
      const frameA = {src: pets.thanksMobilePet1, minDuration: 0.25, maxDuration: 3}
      const frameB = {src: pets.thanksMobilePet2, minDuration: 0.15, maxDuration: 0.35}
      frames.push(frameA);
      frames.push(frameB);
    }
      break;
    case petTypes.SEARCHING:{
      const frameA = {src: pets.searchMobilePet1, minDuration: 0.25, maxDuration: 3}
      const frameB = {src: pets.searchMobilePet2, minDuration: 0.15, maxDuration: 0.35}
      frames.push(frameA);
      frames.push(frameB);
    }
      break;
    case petTypes.FLYING:{
      const frameA = {src: pets.flyingMobilePet1, minDuration: 0.2, maxDuration: 0.3}
      const frameB = {src: pets.flyingMobilePet2, minDuration: 0.23, maxDuration: 0.4}
      const frameC = {src: pets.flyingMobilePet3, minDuration: 0.1, maxDuration: 0.2}
      frames.push(frameA);
      frames.push(frameB);
      frames.push(frameC);
    }
      break;
    case petTypes.TICKET:{
      const frameA = {src: pets.ticketMobilePet1, minDuration: 0.25, maxDuration: 3}
      const frameB = {src: pets.ticketMobilePet2, minDuration: 0.15, maxDuration: 0.35}
      frames.push(frameA);
      frames.push(frameB);
      break;
    }
    case petTypes.EMPTY:{
      const frameA = {src: pets.emptyMobilePet1, minDuration: 0.25, maxDuration: 3}
      const frameB = {src: pets.emptyMobilePet2, minDuration: 0.15, maxDuration: 0.35}
      frames.push(frameA);
      frames.push(frameB);
      break;
    }
    case petTypes.OS:{
      const frameA = {src: pets.osPet1, minDuration: 0.25, maxDuration: 3}
      const frameB = {src: pets.osPet2, minDuration: 0.15, maxDuration: 0.35}
      frames.push(frameA);
      frames.push(frameB);
      break;
    }
    case petTypes.OS2:{
      const frameA = {src: pets.os2Pet1, minDuration: 0.25, maxDuration: 3}
      const frameB = {src: pets.os2Pet2, minDuration: 0.15, maxDuration: 0.35}
      frames.push(frameA);
      frames.push(frameB);
      break;
    }
    case petTypes.LOVE:{
      const frameA = {src: pets.heartMobilePet1, minDuration: 0.25, maxDuration: 3}
      const frameB = {src: pets.heartMobilePet2, minDuration: 0.15, maxDuration: 0.35}
      frames.push(frameA);
      frames.push(frameB);
      break;
    }
    case petTypes.BEER:{
      const frameA = {src: pets.beerPet1, minDuration: 0.25, maxDuration: 3}
      const frameB = {src: pets.beerPet2, minDuration: 0.15, maxDuration: 0.35}
      frames.push(frameA);
      frames.push(frameB);
      break;
    }
    default:
      frames.push(pets.okMobilePet1);
  }

  return frames;
}