const getTextFromMap = (map, language) => {
  let textToReturn = '';
  if(map){
    switch (language) {
      case 'en':
        textToReturn = map.englishValue;
        break;
      case 'es':
      default:
        textToReturn = map.spanishValue;
    }
  }
  return textToReturn;
}

const getTextFromTextList = (list, textId, language) => {
  let textToReturn = textId;
  if (list && Array.isArray(list)){
    list.forEach(text => {
      if(text.textId === textId){
        textToReturn = getTextFromMap(text, language);
      }
    })
  }

  return textToReturn;
}

export {
  getTextFromTextList,
};
