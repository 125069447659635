"use client"
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Input.scss';

function Input({
  id,
  min,
  step,
  max,
  sendValueOnChange,
  sendValueOnBlur,
  type = 'text',
  label = '',
  onClick = null,
  typeTheme = 'Solid',
  theme = 'Black',
  textAlign = 'center',
  fontClass = 'mimItalic',
  uppercase = false,
}){
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState('');

  const onChange = (evt) => {
    let { value } = evt.currentTarget;
    if(uppercase) {
      value = value.toUpperCase();
    }
    setFocus(true)
    setValue(value)
    if(sendValueOnChange){
      sendValueOnChange(value);
    }
  }

  const onFocus = () => {
    setFocus(true)
  }

  const doFocus = () => {
    document.getElementById(id).focus();
  }

  const onBlur = () => {
    if(sendValueOnBlur){
      sendValueOnBlur(value);
    }
    setFocus(false)
  }
  const cssState = !focus ? 'noFocused' : 'focussed';

  return (
    <div className="inputGap">
      <input
        id={id}
        type={type}
        className={`input type${typeTheme} ${theme}Theme ${cssState} ${fontClass} ${textAlign} animated${uppercase ? ' uppercase':''}`}
        min={min}
        max={max}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        step={step}
      />
      <span
        role="button"
        tabIndex="0"
        className={(!focus && !value)  ? `${fontClass} label ${textAlign} ${theme}Theme enable` : 'label disable' }
        onClick={doFocus}
      >
        {label}
      </span>
    </div>
  );
}


Input.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  typeTheme: PropTypes.string,
  theme: PropTypes.string,
  textAlign: PropTypes.string,
  fontClass: PropTypes.string,
  label: PropTypes.string,
  sendValueOnBlur: PropTypes.func,
  sendValueOnChange: PropTypes.func,
  min: PropTypes.number,
  step: PropTypes.number,
  max: PropTypes.number,
  uppercase: PropTypes.bool,
}


export default Input;
