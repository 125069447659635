import React from 'react';
import PropTypes from 'prop-types';
import { valanceImages } from './resources';

export const valanceTypes = {
  RESTAURANT: 'RESTAURANT',
  UNDERLINE: 'UNDERLINE',
  WHITEUNDERLINE: 'WHITEUNDERLINE',
  DIALOG: 'DIALOG',
  CATEGORY: 'CATEGORY',
  TIE: 'TIE',
}

const Valance = ({ type, wrapperClass }) => {
  const {
    restaurantValance,
    underlineTitle,
    whiteUnderlineTitle,
    dialogLine,
    categoryLine,
    tieSeparator,
  } = valanceImages;

  let valanceImgPath = '';

  switch (type) {
    case valanceTypes.RESTAURANT:
      valanceImgPath = restaurantValance;
      break;
    case valanceTypes.CATEGORY:
      valanceImgPath = categoryLine;
      break;
    case valanceTypes.UNDERLINE:
      valanceImgPath = underlineTitle;
      break;
    case valanceTypes.DIALOG:
      valanceImgPath = dialogLine;
      break;
    case valanceTypes.WHITEUNDERLINE:
      valanceImgPath = whiteUnderlineTitle;
      break;
    case valanceTypes.TIE:
      valanceImgPath = tieSeparator;
      break;
    default:
      valanceImgPath = restaurantValance;
  }

  return (
    <div className={`${wrapperClass} valance`}>
      <img src={valanceImgPath} alt="" className="valanceImg" />
    </div>
  )
}

Valance.propTypes = {
  type: PropTypes.string.isRequired,
  wrapperClass: PropTypes.string,
};

export default Valance;