export const textReferences = {
  /* ALL AROUND */
  IMPORTANT: 36,
  GO_BACK: 44,
  TRY_AGAIN: 47,
  I_AM_HERE: 50,
  NO_GO_BACK: 66,
  YES_I_AM_SURE: 53,
  ACCEPT: 84,
  ADD_TO_TICKET: 106,
  /* Connect helper: choose language */
  LANGUAGE_BUTTON_CN: 25,
  /* Connect helper: connection ways */
  WELCOME_CN: 26,
  WHT_IS_YOUR_RESTAURANT:27,
  WHT_IS_YOUR_RESTAURANT_INFO:28,
  SCAN_QR_BUTTON: 29,
  GPS_BUTTON: 30,
  SEARCH_BY_NAME_BUTTON:31,
  /* PROMPT */
  PROMT_CAMERA_TITLE: 32,
  PROMT_GPS_TITLE: 33,
  PROMT_CAMERA_DESCRIPTION: 35,
  PROMT_GPS_DESCRIPTION: 34,
  IN_ORDER_TO_CAMERA: 37,
  IN_ORDER_TO_GPS: 38,
  YELLOW_LINE: 39,
  ASK_PERMISSION: 40,
  /* DENIED */
  DENIED_CAMERA_TITLE:41,
  DENIED_GPS_TITLE:42,
  DENIED_EXPLANATION:43,
  /*  WRONG QR */
  WRONG_QR_TITLE:45,
  WRONG_QR_DESCRIPTION:46,
  /* SCANNING QR */
  SCANNING_QR_DESCRIPTION:48,
  /* LOOKING ON MAP */
  GPS_MAP_SEARCHING_DESCRIPTION: 49,
  /* CONFIRM WHERE YOU ARE */
  CONFIRM_BUSINESS_DESCRIPTION: 51,
  /* SEARCH PANEL */
  SEARCH_PANEL_DESCRIPTION: 54,
  SEARCH_PANEL_CP: 55,
  SEARCH_PANEL_PLACE_CITY: 56,
  SEARCH_PANEL_EMPTY_RESULTS: 57,
  SEACH_PANEL_RESULT_SECTION_TITLE: 58,
  /* CHOOSETABLE SECTION */
  /* TOSEATORNOTTOSEAT */
  SEAT_OR_NOT_TITLE: 59,
  SEAT_OR_NOT_DESCRIPTION:60,
  SEAT_OR_NOT_BUT_YES: 61,
  SEAT_OR_NOT_BUT_NO: 62,
  SEAT_OR_NOT_BOTTOM_WARNING:63,
  /* seguro que quierenes solo sentarte */
  CONFIRM_NOT_TO_SIT_TITLE: 64,
  CONFIRM_NOT_TO_SIT_DESCRIPTION: 65,
  CONFIRM_NOT_TO_SIT_BUTTON_BACK: 66,
  CONFIRM_NOT_TO_SIT_BUTTON_OK: 67,
  /* TypeTableNumber */
  TYPE_TABLE_NUMBER_TITLE: 73,
  TYPE_TABLE_NUMBER_DESCRIPTION: 74,
  TYPE_TABLE_PLACE_HOLDER: 75,
  TYPE_TABLE_BUSY: 188,
  TYPE_TABLE_FREE: 189,
  TYPE_TABLE_WRONG: 190,
  /* confirmation screen 76 77 */
  /* SEATING QUEUE */
  // SET_TABLE_KEY
  SET_TABLE_KEY_TITLE: 78,
  SET_TABLE_KEY_SECOND_TITLE: 79,
  SET_TABLE_KEY_SECOND_FIRST_DESCRIPTION: 80,
  SET_TABLE_KEY_SECOND_SECOND_DESCRIPTION: 81,
  SET_TABLE_KEY_SECOND_THIRD_DESCRIPTION: 82,
  SET_TABLE_KEY_INPUT_LABEL: 83,
  // SET_TABLE_KEY confirm
  SET_TABLE_KEY_SECOND_TITLE_OK: 85,
  SET_TABLE_KEY_SECOND_FIRST_DESCRIPTION_OK: 86,
  SET_TABLE_KEY_SECOND_SECOND_DESCRIPTION_OK: 87,
  SET_TABLE_KEY_BUTTON_OK: 88,
  SET_TABLE_KEY_BUTTON_BACK: 89,
  // INPUT_TABLE_KEY
  INPUT_TABLE_KEY_SECOND_TITLE: 90,
  INPUT_TABLE_FIRST_KEY_DESCRIPTION:91,
  INPUT_TABLE_SECOND_KEY_DESCRIPTION:92,
  // WAITING KING TABLE
  WAITING_KING_SET_KEY_SUBTITLE: 150,
  WAITINT_KING_SET_KEY_DESCRIPTION: 151,
  // WRONG TABLE key
  WRONG_TABLE_KEY_TITLE: 96,
  WRONG_TABLE_KEY_DESCRIPTION:97,
  WRONG_TABLE_KEY_WARNING_TITLE:98,
  WRONG_TABLE_KEY_WARNING_DESCRIPTION:99,
  WRONG_TABLE_KEY_WARNING_BUTTON: 100,
  /* YOUR TABLE FROM CUSTOMER */
  YOUR_TABLE_TITLE: 101,
  YOUR_TABLE_DESCRIPTION: 102,
  YOUR_TABLE_NUMBER_LABEL: 103,
  YOUR_TABLE_NAME_LABEL: 104,
  /* PRODUCT PAGE */
  PRODUCT_PAGE_SIZE: 105,
  /* CUSTOMIZE PRODUCT */
  PRODUCT_CUSTOMIZE_TITLE: 107,
  PRODUCT_CUSTOMIZE_CHOOSE: 108,
  PRODUCT_CUSTOMIZE_DELETE: 109,
  PRODUCT_CUSTOMIZE_SIDE: 110,
  PRODUCT_CUSTOMIZE_SIDES: 111,
  PRODUCT_CUSTOMIZE_EXTRA: 112,
  PRODUCT_CUSTOMIZE_EXTRAS: 113,
  PRODUCT_CUSTOMIZE_NO_EXTRAS: 153,
  /* USER GUIDE */
  /*    INTRO */
  USER_GUIDE_MAIN_TITLE: 114,
  USER_GUIDE_INTRO_FIRST: 115,
  /*    BIG FIRST SECTION */
  USER_GUIDE_FBS_TITLE: 116,
  USER_GUIDE_FBS_SUB_TITLE: 117,
  USER_GUIDE_FBS_INTRO: 118,
  USER_GUIDE_FBS_FIRST_P: 119,
  /* 120 121 picture bottom names */
  USER_GUIDE_FBS_SECOND_P: 122,
  USER_GUIDE_FBS_THIRD_P: 123,
  USER_GUIDE_FBS_FOURTH_P: 124,
  USER_GUIDE_FBS_FIFTH_P: 125,
  USER_GUIDE_FBS_SIXTH_P: 126,
  USER_GUIDE_FBS_SEVENTH_P: 127,
  USER_GUIDE_FBS_EIGHTH_P: 128,
  USER_GUIDE_FBS_NINETH_P: 129,
  USER_GUIDE_FBS_TENTH_P: 130,
  USER_GUIDE_FBS_ELEVENTH_P: 131,
  /*    SECOND BIG SECTION */
  USER_GUIDE_SBS_TITLE: 132,
  USER_GUIDE_SBS_SUB_TITLE: 133,
  USER_GUIDE_SBS_FIRST_P: 134,
  USER_GUIDE_SBS_SECOND_P: 135,
  USER_GUIDE_SBS_THIRD_P: 136,
  USER_GUIDE_SBS_FOURTH_P: 137,
  USER_GUIDE_SBS_FIVETH_P: 138,
  USER_GUIDE_SBS_SECOND_TITLE: 139,
  USER_GUIDE_SBS_BOTTOM_PIC_LABEL: 140,
  USER_GUIDE_SBS_SIXTH_P: 141,
  USER_GUIDE_SBS_SEVENTH_P: 142,
  USER_GUIDE_SBS_EIGHTH_P: 143,
  /* NOTIFICATIONS */
  TABLE_KEY_RIGHT: 152,
  PRODUCT_TICKET_SENDING: 144,
  PRODUCT_ADDED_OK: 145,
  PRODUCT_ADDED_KO: 146,
  PRODUCT_CUSTOMIZATION_KO: 147,
  PRODUCT_KITCHEN_SENDING: 182,
  PRODUCT_KITCHEN_SENDING_WAITING: 185,
  PRODUCT_KITCHEN_SENDING_OK: 186,
  PRODUCT_KITCHEN_SENDING_KO: 187,
  ALL_PRODUCT_KITCHEN_SENDING: 183,
  /* Product Customize extras */
  NUM_EXTRA_ADDED:148,
  NUM_EXTRAS_ADDED:149,
  /* ticket menu */
  READY_TO_ORDER: 154,
  ORDERED_PRODUCTS: 155,
  HOW_TICKET_WORKS: 156,
  TICKET_MENU_TITLE: 157,
  /* TICKET READY_TO_ORDER */
  READY_TO_ORDER_DESCRIPTION: 158,
  READY_TO_ORDER_BUTTON_ALL: 159,
  READY_TO_ORDER_DRINK_TITLE_VALANCE: 160,
  READY_TO_ORDER_FOOD_TITLE_VALANCE: 161,
  READY_TO_ORDER_DRINK_ENUM:162,
  READY_TO_ORDER_FOOD_ENUM:163,
  READY_TO_ORDER_TOTAL_ENUM:164,
  READY_TO_ORDER_SECTION_NAME: 165,
  READY_TO_ORDER_BUTTON_SINGLE:174,
  /* TICKET READY_TO_ORDER EMPTY */
  READY_TO_ORDER_EMPTY_TITLE: 168,
  READY_TO_ORDER_EMPTY_DESCRIPTION: 169,
  /* TICKET ORDERED PRODUCTS */
  ORDERED_PRODUCTS_DESCRIPTION: 180,
  /* TICKET ORDERED PRODUCTS EMPTY */
  ORDERED_PRODUCTS_EMPTY_TITLE:166,
  ORDERED_PRODUCTS_SECTION_NAME:179,
  ORDERED_PRODUCTS_EMPTY_DESCRIPTION: 167,
  /* HOW IT WORKS TICKET */
  HOW_TICKET_WORKS_FIRST_SECTION_NAME:181,
  HOW_TICKET_WORKS_FIRST_TITLE:175,
  HOW_TICKET_WORKS_FIRST_DESCRIPTION:176,
  HOW_TICKET_WORKS_SECOND_TITLE:177,
  HOW_TICKET_WORKS_SECOND_DESCRIPTION:178,
  /* SEARCH SECTION */
  SEARCH_INPUT_TEXT: 170,
  SEARCH_INSTRUCTIONS: 171,
  SEARCH_DESCRIPTION: 172,
  SEARCH_NOT_FOUND: 173,
  SEARCH_ALLERGEN_TITLE: 191,
  SEARCH_ALLERGEN_DESCRIPTION: 192,
  SEARCH_ALLERGEN_OK: 193,
  /* Header */
  DRINK: 6,
  EAT: 7,
  ORDER: 8,
  WHAT_EAT: 10,
  /* BusinessButtonsMenu */
  KNOW_OUR_BUSINESS: 9,
  WHAT_TO_EAT: 10,
  WHAT_TO_DRINK: 11,
  HELP: 12,
  YOUR_TABLE: 13,
  /* EAT */
  // WHAT_EAT: 10,
  /* DRINK */
  // WHAT_TO_DRINK: 11,
  /* ASKING FOR THE BILL */
  ASKING_FOR_THE_BILL_LABEL: 226,
  ASKING_BILL_SECTION_NAME: 252,
  ASKING_BILL_TITLE: 225,
  ASKING_BILL_DESCRIPTION: 227,
  ASKING_BILL_CREDIT_CARD: 250,
  ASKING_BILL_CASH: 251,
  ASKING_BILL_PAYMENT_METHOD: 253,
  /* ASKING FOR THE BILL BLOCK SCREEN */
  BLOCK_SCREEN_PAYMENT_TITLE: 228,
  BLOCK_SCREEN_PAYMENT_DESCRIPTION: 229,
  /* NOT SEATED SCREEN */
  NOT_SEATED_TITLE: 242,
  NOT_SEATED_DESCRIPTION1: 243,
  NOT_SEATED_DESCRIPTION2: 244,
  NOT_SEATED_BUTTON1: 245,
  NOT_SEATED_BUTTON2: 246,

  /* TEXTOS PARA LA WÉ  */
  /* menu home */
  HOME_OPTION_1: 204,
  HOME_OPTION_2: 205,
  HOME_OPTION_3: 206,
  /* menu login */
  HOME_LOGIN_EMAIL: 213,
  HOME_LOGIN_KEY: 214,
  HOME_LOGIN_CONNECT: 215,
  HOME_LOGIN_FORGOT_PASSWORD: 208,
  HOME_LOGIN_SIGN_IN: 207,
  HOME_LOGIN_SIGN_OUT: 209,
  HOME_GO_PANEL_TITLE: 210,
  HOME_GO_PANEL_DESCRIPTION: 211,
  HOME_GO_PANEL_BUTTON:212,


  HOME_SLOGAN: 194,
  HOME_DESCRIPTION1: 195,
  HOME_DESCRIPTION2: 196,
  HOME_DESCRIPTION3: 197,
  BUTTON_KNOW_MORE: 216,
  BUTTON_FREE_TRY: 217,
  HOME_I_WANT_IT_1: 198,
  HOME_I_WANT_IT_2: 199,
  /* BEST EXPERIENCE */
  HOME_BEST_TITLE1: 200,
  /* FOOTER */
  HOME_RIGHTS: 220,
  PRIVACY_POLICY_TEXT: 202,
  PRIVACY_POLICY_LEGALS: 203,
  /* RETRY SCREEN */
  RETRY_TITLE: 222,
  RETRY_DESCRIPTION: 223,
  RETRY_BUTTON: 224,
  /* TEXTS */
  HOME_HELP_TITLE1: 201,

  /* ADMIN */
  ADMIN_TITLE: 218,
}
