/* pet */
const drinkMenuPet1 = '/resources/pets/menu-beber/beber1.svg';
const drinkMenuPet2 = '/resources/pets/menu-beber/beber2.svg';
const eatMenuPet1 = '/resources/pets/menu-comer/comer1.svg';
const eatMenuPet2 = '/resources/pets/menu-comer/comer2.svg';
const helpMobilePet1 = '/resources/pets/movil-ayuda/ayuda1.svg';
const helpMobilePet2 = '/resources/pets/movil-ayuda/ayuda2.svg';
const searchMobilePet1 = '/resources/pets/movil-buscar/buscar1.svg';
const searchMobilePet2 = '/resources/pets/movil-buscar/buscar2.svg';
const heartMobilePet1 = '/resources/pets/movil-corazon/corazon1.svg';
const heartMobilePet2 = '/resources/pets/movil-corazon/corazon2.svg';
const errorMobilePet1 = '/resources/pets/movil-error/error1.svg';
const errorMobilePet2 = '/resources/pets/movil-error/error2.svg';
const defaultMobilePet1 = '/resources/pets/movil-normal/normal1.svg';
const defaultMobilePet2 = '/resources/pets/movil-normal/normal2.svg';
const okMobilePet1 = '/resources/pets/movil-ok/ok1.svg';
const okMobilePet2 = '/resources/pets/movil-ok/ok2.svg';
const thanksMobilePet1 = '/resources/pets/movil-thanks/thanks1.svg';
const thanksMobilePet2 = '/resources/pets/movil-thanks/thanks2.svg';
const ticketMobilePet1 = '/resources/pets/movil-ticket/ticket1.svg';
const ticketMobilePet2 = '/resources/pets/movil-ticket/ticket2.svg';
const emptyMobilePet1 = '/resources/pets/movil-vacio/vacio1.svg';
const emptyMobilePet2 = '/resources/pets/movil-vacio/vacio2.svg';
const flyingMobilePet1 = '/resources/pets/movil-vuela/vuela1.svg';
const flyingMobilePet2 = '/resources/pets/movil-vuela/vuela2.svg';
const flyingMobilePet3 = '/resources/pets/movil-vuela/vuela3.svg';
const noOrderMobilePet1 = '/resources/pets/no-pediste/pediste1.svg';
const noOrderMobilePet2 = '/resources/pets/no-pediste/pediste2.svg';
const cloudPet1 = '/resources/pets/nube/nube1.svg';
const cloudPet2 = '/resources/pets/nube/nube2.svg';
const cloudPet3 = '/resources/pets/nube/nube3.svg';
const cloudPet4 = '/resources/pets/nube/nube4.svg';
const cloudPet5 = '/resources/pets/nube/nube5.svg';
const cloudPet6 = '/resources/pets/nube/nube6.svg';
const cloudPet7 = '/resources/pets/nube/nube7.svg';
const cloudPet8 = '/resources/pets/nube/nube8.svg';
const cloudPet9 = '/resources/pets/nube/nube9.svg';
const cloudPet10 = '/resources/pets/nube/nube10.svg';
const cloudPet11 = '/resources/pets/nube/nube11.svg';
const cloudPet12 = '/resources/pets/nube/nube12.svg';
const cloudPet13 = '/resources/pets/nube/nube13.svg';
const cloudPet14 = '/resources/pets/nube/nube14.svg';
const osPet1 = '/resources/pets/movil-os/os1.svg';
const osPet2 = '/resources/pets/movil-os/os2.svg';
const os2Pet1 = '/resources/pets/movil-os-2/os21.svg';
const os2Pet2 = '/resources/pets/movil-os-2/os22.svg';
const beerPet1 = '/resources/pets/movil-beer/beer1.svg';
const beerPet2 = '/resources/pets/movil-beer/beer2.svg';

export const petTypes = {
  EATMENU: 'EATMENU',
  DRINKMENU: 'DRINKMENU',
  HELP: 'HELP',
  NORMAL: 'NORMAL',
  SAD: 'SAD',
  OK: 'OK',
  THANKS: 'THANKS',
  SEARCHING: 'SEARCHING',
  FLYING: 'FLYING',
  CLOUDS: 'CLOUDS',
  TICKET: 'TICKET',
  EMPTY: 'EMPTY',
  OS: 'OS',
  OS2: 'OS2',
  LOVE: 'LOVE',
  BEER: 'BEER',
  SLEEPING: 'SLEEPING',
}

export const pets = {
  drinkMenuPet1,
  drinkMenuPet2,
  eatMenuPet1,
  eatMenuPet2,
  helpMobilePet1,
  helpMobilePet2,
  searchMobilePet1,
  searchMobilePet2,
  heartMobilePet1,
  heartMobilePet2,
  errorMobilePet1,
  errorMobilePet2,
  defaultMobilePet1,
  defaultMobilePet2,
  okMobilePet1,
  okMobilePet2,
  thanksMobilePet1,
  thanksMobilePet2,
  ticketMobilePet1,
  ticketMobilePet2,
  emptyMobilePet1,
  emptyMobilePet2,
  flyingMobilePet1,
  flyingMobilePet2,
  flyingMobilePet3,
  noOrderMobilePet1,
  noOrderMobilePet2,
  cloudPet1,
  cloudPet2,
  cloudPet3,
  cloudPet4,
  cloudPet5,
  cloudPet6,
  cloudPet7,
  cloudPet8,
  cloudPet9,
  cloudPet10,
  cloudPet11,
  cloudPet12,
  cloudPet13,
  cloudPet14,
  osPet1,
  osPet2,
  os2Pet1,
  os2Pet2,
  beerPet1,
  beerPet2,
}