"use client"
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pets } from './pets';
import { setUpPetFromLibrary, giveMeRandomDurationInMilliSecondsForFrame } from './pets-factory/';
import './PetImage.scss';


class PetImage extends Component{
  constructor(props){
    super(props);
    const { type } = props;
    this.state = {
      currentFrame: 0,
      currentAnimationRunning: -1,
      frames: setUpPetFromLibrary(pets,type),
    }
  }

  nextFrameId = () => {
    const { currentFrame, frames } = this.state;
    const maxFrames = frames.length;
    let newFrame = 0;
    if (currentFrame !== ((maxFrames -1)) && (currentFrame < maxFrames)) {
      newFrame = currentFrame + 1;
    }
    this.setState({currentFrame: newFrame});
    return newFrame;
  }

  animate = () => {
    const { currentFrame, frames } = this.state;
    const { minDuration, maxDuration } = frames[currentFrame];
    let durationFrame;

    if(minDuration && maxDuration){
      durationFrame = giveMeRandomDurationInMilliSecondsForFrame(minDuration,maxDuration);
    } else {
      durationFrame = giveMeRandomDurationInMilliSecondsForFrame();
    }

    const frameTime = setTimeout(() => {
      this.nextFrameId();
      // console.log(`frameTime: ${frameTime} currentFrame: ${currentFrame} nextFrameId: ${nextFrameId} durationFrame: ${durationFrame}`);
      clearTimeout(frameTime);
      // console.log(`frameTime muere: ${frameTime}`);
      this.animate();
    }, durationFrame);

    this.setState({currentAnimationRunning: frameTime});
  }

  componentDidMount = () => {
    const { frames } = this.state;
    if(frames.length > 0){
      this.animate();
    }
  }


  componentWillUnmount = () => {
    const { currentAnimationRunning } = this.state;
    clearInterval(currentAnimationRunning);
  }

  render() {
    const { frames, currentFrame } = this.state;
    const { children, className } = this.props;
    if(frames){
      return (
        <div className={`pet petRegularSize${className ? ` ${className}`:''}`}>
          {
            frames.map((frame, index) => {
              const key = `pet-${index}`;
              const hiddenOrNot = index === currentFrame ? 'fullOpacity' : 'noOpacity';
              return (<img key={key} className={`petImg petRegularSize ${hiddenOrNot}`} id={`pet-${index}`} src={frame.src} alt="" />);
            })
          }
          {children}
        </div>
      )
    }
    return null;
  }
}

export default PetImage



PetImage.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node,
};
