export const TAB_WEB_NAME = 'MIM';
export const BASE_WEBSITE_URL = 'https://mim.restaurant';
// export const URL_API = '//mim.cool:4433/mim/v1/client/';
export const URL_API = 'http://mim.cool:4001/api/v1/';
export const URL_API_LOCAL = '//localhost:4001/api/v1/';
export const MAX_MIM_PLACE_DISTANCE = 10000;
//85.214.69.84:9990/
export const URL_INGREDIENTS = '/resources/allergens';
export const FRAME_MAX_DURATION = 2;
export const FRAME_MIN_DURATION = 0.25;

export const SPANISH_LANGUAGE = 'es';
export const ENGLISH_LANGUAGE = 'en';

export const LANGUAGES = [ SPANISH_LANGUAGE, ENGLISH_LANGUAGE ];

export const CAMERA_PERMISSION = 'camera';
export const CAMERA_FLAG_COOKIE_NAME = 'CookieQRFlag';
export const LOCATION_PERMISSION = 'location';
export const LOCATION_FLAG_COOKIE_NAME = 'CookieLocationFlag';

export const SEARCH_BY_BUSINESS_NAME = 'businessName';
export const SEARCH_BY_CITY_NAME = 'cityName';
export const SEARCH_BY_CN_BN = 'cityBusinessName';

export const GUEST = 'guest';

export const MIM = 'mim';
export const EAT = 'eat';
export const DRINK = 'drink';
export const FOOD = 'food';
export const SEARCH_SECTION = 'search';
export const CUSTOMIZEPRODUCT = 'customizeProduct';
export const ORDER = 'order';

// export const PENDING_KITCHEN_SOCKET_SERVER = '//mim.cool:4433/mim/v1/client/orderWS';
export const PENDING_KITCHEN_SOCKET_SERVER = 'http://85.214.69.84:8080/mim/v1/client/orderWS';
export const PENDING_KITCHEN_SOCKET_LOCAL = '//localhost:8080//mim/v1/client/orderWS';

// export const SITTING_ON_TABLE_SERVER = '//mim.cool:4433/mim/v1/client/tableWS';
export const SITTING_ON_TABLE_SERVER = 'http://85.214.69.84:8080/mim/v1/client/tableWS';
export const SITTING_ON_TABLE_LOCAL = '//localhost:8080//mim/v1/client/tableWS';

export const SET_TABLE_KEY = 'SET_TABLE_KEY';
export const WAITING_FOR_TABLE_KEY = 'WAITING_FOR_TABLE_KEY';
export const INPUT_TABLE_KEY = 'INPUT_TABLE_KEY';
export const TABLE_NOT_FOUND = 'TABLE_NOT_FOUND';

export const NAV_BACK_EN = 'Back';
export const NAV_BACK_ES = 'Volver';

export const NO_HAVE_SECTION_EN = 'Various';
export const NO_HAVE_SECTION_ES = 'Varios';

export const CURRENCY = '€';



export const TOAST_CONTAINER_PROPS = {
  position: "top-right",
  autoClose: 3500,
  closeButton: false,
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: false,
  rtl: false,
  pauseOnVisibilityChange: true,
  draggablePercent: 20,
  draggable: true,
  pauseOnHover: false,
  pauseOnFocusLoss: false,
  bodyClassName: 'notificationsContainer',
  progressClassName: 'notificationProgressBar',
}

export const SECTION1_ID = 'what-is-it';
export const SECTION2_ID = 'i-want-it';
export const SECTION3_ID = 'contact';
